import { useRef, useState, useEffect } from "react";
import "./styles/AboutInfoStyles.css";

function AboutInfoTemplate({ head }) {
    const [IsOpen, setIsOpen] = useState(false);
    const textRef = useRef(null);

    const toggleOpen = () => {
        setIsOpen(!IsOpen);
        if (textRef.current) {
            textRef.current.style.maxHeight = IsOpen ? '0' : `${textRef.current.scrollHeight}px`;
        }
    };

    useEffect(() => {
        if (textRef.current) {
            textRef.current.style.maxHeight = IsOpen ? `${textRef.current.scrollHeight}px` : '0';
        }
    }, [IsOpen]);

    return (
        <div className="AboutInfoTemplate">
            <h1 onClick={toggleOpen} className={IsOpen ? 'xxeder open' : 'xxeder'}>
                {head}
            </h1>
            <div ref={textRef} className={`textt ${IsOpen ? 'open' : ''}`}>
                <p>Я искренне верю в то, что можно помочь человеку изменить жизнь.
                    Поэтому более 15 лет я работаю клиническим психологом.
                    <br/>
                    Мне нравится учить людей самим справляться с проблемами – это повышает качество их жизни!
                    <br/>
                    <br/>
                    Я закончила с отличием  факультет "Психологическое консультирование" МГППУ, потом училась
                    клинической психологии   в Центральной государственной медицинской академии.
                    <br/>
                    <br/>
                    Я специализируюсь на когнитивно-поведенческой психотерапии (КПТ), потому что ее отличает:
                    <br/>
                    - научно доказанная эффективность
                    <br/>
                    - краткосрочность
                    <br/>
                    - технологичность
                    <br/>
                    - применимость для широкого круга проблем
                    <br/>
                    <br/>
                    Я начала с базового курса КПТ, два года училась на продвинутом курсе КПТ у А.Б. Холмогоровой и Н.Г.
                    Гаранян, а потом закончила курс когнитивной терапии Оксфордского центра.
                    <br/>
                    <br/>
                    КПТ динамично развивается, поэтому я – постоянный участник воркшопов и конференций.
                    <br/>
                    <br/>
                    Помимо классической КПТ я обучалась и продолжаю обучение современным направлениям психотерапии:
                    <br/>
                    - Схематерапия
                    <br/>
                    - Терапия принятия и ответственности (АСТ)
                    <br/>
                    - Терапия, фокусированная на сострадании (СFT)
                    <br/>
                    - Унифицированный трансдиагностический протокол Барлоу (UPB).
                    <br/>
                    - Десенсибилизация и переработка с помощью движений глаз (EMDR)
                    <br/>
                    <br/>
                    Достижения этих направлений я также применяю в работе.
                    <br/>
                    <br/>
                    Я вхожу в Ассоциацию когнитивно-бихевиоральной терапии, в Ассоциацию EMDR RUSSIA.
                    <br/>
                    <br/>
                    Читаю много профессиональной литературы и регулярно посещаю супервизии и интервизии, чтобы
                    советоваться с опытными коллегами. Регулярно посещаю личную психотерапию.
                    <br/>
                    <br/>
                    С 2020 я пишу кандидатскую диссертацию по профессиональной этике в Психологическом институте РАО,
                    у меня есть научные статьи на тему этики.
                    <br/>
                    <br/>
                    Я - соосновательница Коллегии по этике в психологии и психотерапии, миссия которой - повышение
                    этической осознанности профессионалов и этической грамотности клиентов.
                </p>
            </div>
        </div>
    );
}

export default AboutInfoTemplate;
