import {Link} from "react-router-dom";
import "./styles/Approval.css"
import {useEffect} from "react";
import {animateScroll as scroll} from "react-scroll";

function Approval(){
    useEffect(() => {
        scroll.scrollToTop({
            smooth: true,
            duration: 300,
        })
    }, []);
    return (
        <div className="approval-container">
            <Link to={"/"}>
                <h1 className="approval-title">Информированное<br/>согласие</h1>
            </Link>
            <div className="approval-info">
                <p className="approval-message">
                    Данный документ содержит важную информацию, относящуюся к консультативным
                    услугам, которые я, Захарова Юлия Владимировна, клинический психолог, предоставляю вам.
                    Пожалуйста, найдите время, чтобы внимательно его прочитать. Если у вас есть какие-либо
                    вопросы - обсудите их, пожалуйста, со мной.
                    <br/>
                    <br/>
                    <b>ЧТО ВЫ МОЖЕТЕ ОЖИДАТЬ ОТ КОНСУЛЬТИРОВАНИЯ</b>
                    <br/>
                    <br/>
                    На консультировании вы можете обсудить различные проблемы,
                    связанные с вашим здоровьем, колебаниями настроения, печалью,
                    беспокойством, конфликтами, отношениями, работой, учебой, с
                    принятием непростых решений и другие важные для вас вопросы.
                    <br/><br/>
                    Я работаю со взрослыми и детьми старше 15 лет. Я не работаю с
                    детьми младше 15 лет, но могу проконсультировать вас относительно
                    их воспитания и взаимодействия с ними.
                    <br/><br/>
                    Я не работаю с клиентами с химическими зависимостями: с алкоголизмом,
                    наркоманией. Я не работаю с клиентами в психотическом состоянии.
                    Если вы знаете, что у вас есть диагноз, поставленный психиатром,
                    прошу вас сообщить мне об этом сразу.
                    <br/><br/>
                    С информацией о моей квалификации и используемых методах работы вы можете ознакомиться в разделе
                    "Обо мне".
                    <br/><br/>
                    Я буду работать во взаимодействии с вами, чтобы определить круг проблем,
                    наметить цели и стратегии для их решения. Я надеюсь, что мы приложим все
                    усилия, чтобы добиться положительных результатов, однако этот процесс не
                    всегда является легким, и нет никаких гарантий, что результат будет достигнут.
                    <br/><br/>
                    Если в ходе консультирования у вас появятся вопросы о предоставляемых вам услугах или
                    вы поймете, что вас что-то не устраивает - пожалуйста, не стесняйтесь обсуждать со мной
                    эти проблемы.
                    <br/>
                    <br/>
                    <b>СЕТТИНГ</b>
                    <br/>
                    <br/>
                    Продолжительность сессии составляет 50 минут. Количество сессий в неделю определяется
                    индивидуально. Я постараюсь выбрать для сессий удобное для вас время.
                    <br/>
                    <br/>
                    <b>Правила отмены и переноса, оплаты сессий.</b>
                    <br/>
                    <br/>
                    Время, которое будет выбрано для консультирования, отведено только для вас.
                    Если вам необходимо отменить или перенести онлайн-сессию – сделайте это,
                    пожалуйста, накануне встречи, не позднее 21.00 мск. Если вам необходимо отменить
                    или перенести очную сессию – сделайте это, пожалуйста, не позднее, чем за
                    сутки до встречи. Я постараюсь найти ближайшее время для переноса сеанса.
                    Сессии, отмененные или перенесенные не по правилам, оплачиваются в полном размере.
                    Также оплачиваются сессии, пропущенные без предупреждения.
                    <br/><br/>
                    Сессии оплачиваются в день консультации, также возможна предоплата.
                    <br/>
                    <br/>
                    <b>Опоздания.</b>
                    <br/>
                    <br/>
                    Старайтесь приходить на сессии вовремя. При опозданиях будьте, пожалуйста, готовы к
                    тому, что сессия не будет продлена на время вашего опоздания и закончится вовремя, а
                    стоимость сессии при этом не изменится.
                    <br/>
                    <br/>
                    <b>Взаимодействие вне сессий.</b>
                    <br/>
                    <br/>
                    Прошу вас отнестись с пониманием к тому, что я не провожу консультирование по телефону,
                    в почте и в мессенджерах. Я консультирую только в заранее оговоренное время.<br/><br/>
                    Для связи со мной я прошу вас использовать текстовые сообщения через WhatsApp и рабочую
                    почту <b>mail@zakharova.info</b>. Пишите мне, пожалуйста, в течение рабочего дня с 11-00 до 22-00 в
                    будни.<br/><br/>
                    Я могу ответить вам с существенной задержкой, пожалуйста, не принимайте это на свой счет.
                    Я могу вести прием других клиентов, быть на конференции, супервизии, обучении,
                    заниматься личными делами. Я постараюсь ответить вам в течении рабочего дня.<br/><br/>
                    Прошу вас звонить мне только по согласованию и только если такую возможность мы специально
                    оговорили в ходе консультирования.
                    <br/>
                    Если вам срочно требуется психологическая помощь и поддержка, я рекомендую вам звонить
                    на единый всероссийский телефон доверия
                    <b>051</b> с городского телефона (бесплатно);<br/>
                    <b>8 (495) 051</b> (с мобильного телефона)
                    либо в Центр экстренной психологической помощи МЧС России <b>(8 (495) 989-50-53)</b>.
                    <br/>
                    <br/>
                    <b>Множественные отношения.</b>
                    <br/>
                    <br/>
                    Если мы с вами вступили в консультативные отношения, то для нашей работы важно,
                    чтобы у нас не было никаких других отношений. Прошу вас не френдить меня в соцсетях.
                    Если мы встречаемся в публичных пространствах, пожалуйста, помните, что по этическим
                    соображениям я не могу поздороваться с вами первой.
                    <br/>
                    <br/>
                    <b>Окончание консультирования.</b>
                    <br/>
                    <br/>
                    Если вы решите прекратить консультирование, сообщите мне об этом, пожалуйста. Это никак не повлияет
                    на вашу возможность обратиться ко мне снова.<br/><br/>
                    Если вы собираетесь прервать консультирование и пропускаете без уведомления сеанс, то помните,
                    пожалуйста, что в дальнейшем я могу отказаться работать с вами.
                    <br/>
                    <br/>
                    В ряде случаев может выясниться, что я не имею достаточной квалификации для оказания вам услуг.
                    Тогда мы будем обсуждать возможность вашего обращения к другому специалисту, компетентному в этой
                    области. Я поддерживаю контакты с коллегами и специалистами смежных специальностей и постараюсь
                    оказать вам содействие в поиске специалиста, который будет более эффективен в этой ситуации.
                    <br/>
                    <br/>
                    <b>КОНФИДЕНЦИАЛЬНОСТЬ</b>
                    <br/>
                    <br/>
                    Вопросы, обсуждаемые в ходе консультирования, являются конфиденциальными. Я поддерживаю самые
                    высокие этические и правовые нормы, касающиеся вашей частной жизни и конфиденциальности.
                    <br/>
                    <br/>
                    Однако, есть некоторые ситуации, при которых в соответствии с законодательством, конфиденциальность
                    может быть нарушена:
                    <br/>
                    <br/>
                    1. Клиент представляет опасность для себя или других.
                    <br/>
                    2. Клиент раскрывает информацию о злоупотреблениях в отношении детей, пожилых людей, людей с
                    инвалидностью.
                    <br/>
                    При наступлении таких ситуаций, чтобы попытаться обеспечить вашу безопасность, а также безопасность
                    других лиц,
                    я должна буду проинформировать о ситуации правоохранительные органы.
                    <br/>
                    <br/>
                    Вы также даете согласие на то, что разрешаете мне обсуждать информацию, полученную
                    в ходе консультационной сессии, с целью увеличения моей эффективности в работе над вашим
                    случаем с другими специалистами: вашим лечащим врачом-психиатром, в ходе интервизий и супервизий
                    (при этом ваши персональные данные разглашаться не будут).
                </p>
            </div>
            <Link to="/" className="approval-link">
                <button className="approval-button">Домой</button>
            </Link>
        </div>
    );
}

export default Approval;